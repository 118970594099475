:root {

	--color-blue-main: #344559;
	--color-blue-dark: #2d3e52;
	--color-blue-medium: #99AABE;
	--color-blue-light: #D2E3EA;
	--color-blue-highlights: #07a8dc;
	--color-blue-link: #30618C;
	--color-grey: #eeeeee;
	--color-grey-light: #f8f8f8;

	--color-white: #ffffff;

	--color-main-font: #7a7b7d;
	--color-title-font: #2d3e52;

	--font-family-main: 'Open Sans', sans-serif;
	--font-family-title: 'Hind', sans-serif;

	--h1-font-size: 28px;
	--h2-font-size: 24px;
	--h3-font-size: 20px;
	--h4-font-size: 18px;


	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--primary: #ff9631;
	--secondary: var(--color-blue-main);
	--lightblue: #e9eff6;
	--black: #000000;
	--white: #fff;
	--bodyfont: 'Poppins', sans-serif;
	--grey: #4e4e4e;
	--light-grey: #f5f5f5;
	--darkgrey: #666666;
	--black2: #333333;
	--green: #01bd86;
	--black3: #14141f;
	--grey2: #b7b7b7;
	--red: #ff0000;
	--grey3: #8a8a8a;
	--black4: #484848;
	--darkblue: #0d2e53;
	--black5: #4d4d4d;

	/*Calendar colors*/
	--not_available: #EC4747;
	--disabled: #fff;
	--available: #fff;
	--selected: var(--color-blue-main);
	--selection_range: var(--color-blue-medium);
	--selected_border: #fff;
	--text_color: #333;
}