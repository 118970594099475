.static {
	display: block;
}

.inner-banner {
	background-image: url(RESOURCE/img/bg-banner-contact.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 320px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
		margin-top: 60px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		.team-dt {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}

.ce_text {
	line-height: 1.5;
	margin-bottom: 20px;

	a {
		text-decoration: underline;
	}
}

.region-view {

	padding-top: 40px;
	padding-bottom: 40px;


	.container {
		@media (min-width: 1200px) {
			width: 1170px;
		}

	}

}

.sidebar-nav {
	background-color: var(--color-blue-main);
	padding: 20px;
	margin-bottom: 20px;

	ul {
		margin: 0px;
	}

	a {
		display: block;
		line-height: 30px;
		color: var(--color-main-font);
		background-color: var(--color-white);
		text-indent: 10px;
		margin-bottom: 1px;


		&:hover {
			color: var(--color-white);
			background-color: var(--color-blue-medium);
		}

		&.active {
			color: var(--color-white);
			background-color: var(--color-blue-medium);
		}

		&:before {
			margin-right: 6px;
			font-family: Font Awesome\ 6 Free;
			font-weight: 900;
			font-size: 12px;
			content: "\f0da";

		}


	}

}

.service-img {
	border: 1px solid var(--color-grey);
}

.serviceinfo {
	background-color: var(--color-blue-light);
	padding: 15px;
	color: var(--color-blue-dark);

	.con-headline {
		font-size: 18px;
		margin-bottom: 20px;
		color: var(--color-blue-dark);

		.far {
			color: var(--color-blue-medium);
			height: 32px;
			width: 32px;
			border: 1px solid #fff;
			border: 2px solid var(--color-blue-medium);
			line-height: 28px;
			padding-left: 6px;
			border-radius: 50%;
			margin-left: -3px;
			background: #FFF;
		}
	}

	.con-icon {
		padding-left: 5px;
	}

	.ask-icon {
		padding-left: 5px;
	}

	a {
		color: var(--color-blue-dark);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.faq-view {

	padding-top: 40px;
	padding-bottom: 40px;

	.faq {

		margin-top: 30px;

		h3 {
			font-size: 22px;

			&:before {
				width: 25px;
				height: 25px;
				left: 0;
				top: 0;
				float: left;
				display: block;
				margin-right: 10px;
				text-align: center;
				background-color: #99aabe;
				border-radius: 100%;
				font-family: Font Awesome\ 6 Free;
				font-weight: 900;
				font-size: 19px;
				color: #fff;
				content: "\f128";
				margin-top: -2px;
				line-height: 25px;
			}
		}

		p {
			margin-bottom: 40px;
			padding: 20px;
			background-color: #F8F8F8;
			border: 1px solid #eee;
		}

	}
}

.servicebueros-view {

	padding-top: 40px;
	padding-bottom: 40px;

	.buero {
		line-height: 1.5;

		.img {
			margin-bottom: 20px;
		}
	}

}

.buero-last {
	margin-top: 20px;
}

.static-view {

	padding-top: 40px;
	padding-bottom: 40px;

}

.route_button.icon_mappin:before {
	color: #fff
}

.route_button {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #99aabe, #344559) repeat scroll 0 0;
	border: 2px solid #bbcce0;
	border-radius: 2px;
	box-sizing: border-box;
	color: #fff;
	font: 16px/30px "Hind", sans-serif;
	height: 30px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	text-decoration: none;

	&:hover {
		color: #fff;
	}
}


.icon_mappin:before {
	display: inline-block;
	margin-right: 8px;
	margin-left: 2px;
	font-weight: 900;
	text-decoration: none;
	color: #99aabe;
	content: "\f041";

	font-family: Font Awesome\ 6 Free;

}

.spacer {
	height: 2px;
	border-bottom: 1px solid #eee;
	margin-top: 30px;
	margin-bottom: 30px;
}

.anreise {
	margin-top: 30px;

	p {
		margin-bottom: 30px;
	}

	.line {
		border-bottom: 1px solid #eee;
		height: 2px;
		margin-bottom: 30px;
	}

	.download {
		margin-bottom: 10px;

		&.last {
			margin-bottom: 30px;
		}
	}

}

.mime_icon {
	margin-right: 5px;
}

.akt-hint {
	padding-top: 40px;
}

.contactform {
	margin-top: 30px;
	padding: 20px;
	background-color: var(--color-grey-light);
	border: 1px solid var(--color-grey);
}