/* hind-300 - latin */
@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/hind-v11-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/hind-v11-latin-300.woff') format('woff');
	font-display: swap;
}

/* hind-regular - latin */
@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/hind-v11-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/hind-v11-latin-regular.woff') format('woff');
	font-display: swap;
}

/* hind-500 - latin */
@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/hind-v11-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/hind-v11-latin-500.woff') format('woff');
	font-display: swap;
}

/* hind-600 - latin */
@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/hind-v11-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/hind-v11-latin-600.woff') format('woff');
	font-display: swap;
}

/* hind-700 - latin */
@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/hind-v11-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/hind-v11-latin-700.woff') format('woff');
	font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v26-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/open-sans-v26-latin-regular.woff') format('woff');
	font-display: swap;
}

/* open-sans-500 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v26-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/open-sans-v26-latin-500.woff') format('woff');
	font-display: swap;
}

/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v26-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/open-sans-v26-latin-700.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 6 Free';
	src: url('RESOURCE/fonts/FontAwesome6Free-Solid.eot');
	src: url('RESOURCE/fonts/FontAwesome6Free-Solid.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FontAwesome6Free-Solid.woff2') format('woff2'),
		url('RESOURCE/fonts/FontAwesome6Free-Solid.woff') format('woff'),
		url('RESOURCE/fonts/FontAwesome6Free-Solid.ttf') format('truetype'),
		url('RESOURCE/fonts/FontAwesome6Free-Solid.svg#FontAwesome6Free-Solid') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 6 Free';
	src: url('RESOURCE/fonts/FontAwesome6Free-Regular.eot');
	src: url('RESOURCE/fonts/FontAwesome6Free-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FontAwesome6Free-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/FontAwesome6Free-Regular.woff') format('woff'),
		url('RESOURCE/fonts/FontAwesome6Free-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/FontAwesome6Free-Regular.svg#FontAwesome6Free-Solid') format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 6 Brands';
	src: url('RESOURCE/fonts/FontAwesome6Brands-Regular.eot');
	src: url('RESOURCE/fonts/FontAwesome6Brands-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FontAwesome6Brands-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/FontAwesome6Brands-Regular.woff') format('woff'),
		url('RESOURCE/fonts/FontAwesome6Brands-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/FontAwesome6Brands-Regular.svg#FontAwesome6Free-Solid') format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}