@import "setup.css";

header.vo-nav-bar {
	padding: 0;
}

.vo-nav-bar {
	background-color: var(--color-blue-main);
	border-top: 1px solid #b0b0b0;
	padding: 12px 0;
	width: 100%;



	.main-menu {

		@media (max-width:992px) {
			position: absolute;
			left: 0;
			top: 100%;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				margin-right: 32px;

				@media (max-width:992px) {
					display: block;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					color: var(--color-white);
					font-weight: 500;

					&:hover {
						color: var(--color-blue-medium);
					}
				}
			}
		}

		.btn {
			@media (max-width:340px) {
				font-size: 12px;
				padding: 8px 8px;
			}
		}
	}
}

header {
	background-color: var(--white);
	position: relative;

	@media (max-width:992px) {
		/* position: fixed; */
		width: 100%;
		z-index: 10;
		top: 0;
	}

	.header-middle {
		background-color: var(--color-blue-main);

		.container {
			@media (max-width:992px) {
				width: 100%;
			}
		}

		.mainnav {
			padding-top: 20px;
			float: right;

			.navbar-collapse {
				padding-left: 0px;
				padding-right: 0px;
			}

		}

	}

	.header-top {
		background-color: var(--color-blue-dark);
		padding: 10px;
		font-size: 12px;
		color: var(--color-white);

		@media (max-width: 992px) {
			display: none;
		}


		.nowrap {
			@media (min-width: 992px) {
				white-space: nowrap
			}
		}

		.header-tel {
			font-size: 12px;
			padding-top: 5px;

			@media (max-width: 992px) {
				text-align: center;
			}

			.fa {
				font-size: 12px;
			}

			.tel {
				white-space: nowrap;
				display: inline-block;

				@media (max-width: 992px) {
					font-size: 13px;
					padding-bottom: 2px;
				}
			}
		}

		.favorits {
			padding-top: 5px;
		}

		.btn {
			@media (max-width:340px) {
				font-size: 12px;
				padding: 8px 8px;
			}
		}


		ul {
			padding: 0;
			margin: 0;
			display: inline-block;

			li {
				display: inline-block;
				margin-left: 30px;
				position: relative;

				@media (max-width:992px) {
					display: block;
				}

				a {
					color: var(--color-blue-medium);
					text-decoration: none;
					font-size: 13px;
				}

				a:hover {
					color: var(--color-white);
				}



				&.fav-count {
					.fa {
						font-size: 12px;
					}

				}
			}
		}
	}

	.header-main {

		.header-logo {

			text-align: center;
			padding: 10px;

			@media (min-width:993px) {
				display: inline-block;
				padding-left: 0px;
			}

			.logo {
				max-width: 340px;
				padding: 0px;

				@media (max-width:1199px) {
					max-width: 300px;
				}
			}

		}
	}



}

.navbar {

	.mobile-navigation {
		display: none;
	}

	ul {
		margin: 0;
		padding: 0px;

		&.two-col-drop {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			width: 440px;

			li {
				@media (min-width:993px) {
					width: 50% !important;
				}
			}
		}

		li {
			list-style: none;
			padding: 0px;
			margin: 0px 5px;

			@media (max-width:1240px) {
				margin: 0 2px;
			}

			a {
				font-size: 18px;
				font-family: var(--font-family-title);
				color: var(--color-white);
				display: inline-block;
				padding-left: 20px;
				background: transparent;
				text-align: left;

				i {
					margin-left: 5px;
				}

				@media (max-width:1240px) {
					font-size: 16px;
				}

				@media (max-width:992px) {
					padding: 7px 0;

				}

				span {
					padding: 3px 5px;
					border-radius: 3px;
					background-color: var(--secondary);
					color: var(--color-white);
					display: inline-block;
					line-height: 13px;
					font-size: 14px;
					margin-left: 3px;
				}
			}

			&:hover {
				a {
					color: var(--color-blue-medium);

				}

				.dropdown-menu {
					display: block;
				}
			}

			.dropdown-menu {
				min-width: 190px;
				left: 0px;
				margin: 0px;
				padding: 0;
				background-color: var(--color-white);
				border: 1px solid var(--color-blue-light);
				border-radius: 0 3px 3px 3px;
				margin-top: -1px;

				@media (max-width:992px) {
					ul {
						padding: 0;
					}
				}

				li {
					float: left;
					width: 100%;
					margin: 0px;
					text-indent: 20px;


					&:last-child {
						border-bottom: none;
					}
				}

				a {
					font-size: 15px;
					display: block;
					padding: 5px;
					color: var(--color-main-font);

					&:hover {
						background-color: var(--color-blue-medium);
						color: var(--color-white);
					}
				}
			}
		}


	}

}

.header-bottom {
	background-color: var(--color-blue-main);
	padding: 10px;
	text-align: center;
	margin: auto;
	width: 60%;

}

.header-search {

	.form-control {
		height: 28px;
		padding: 0 10px;
		width: 100%;
		background-color: var(--color-blue-dark);
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border: 1px solid var(--color-blue-medium);
		color: var(--color-white);
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--color-blue-medium);
		opacity: 1;
	}

	.input-group-btn {
		.btn-default {
			height: 28px;
			padding: 0 10px;
			border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;
			border: 1px solid var(--color-blue-medium);
			background-color: var(--color-blue-dark);

			.fas {
				color: var(--color-blue-medium);
			}
		}
	}
}

.header-search:hover {
	.form-control {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border: 1px solid var(--color-white)
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--color-white);
		opacity: 1;
	}

	.input-group-btn {
		.btn-default {
			border: 1px solid var(--color-white);

			.fas {
				color: var(--color-blue-medium);
			}
		}
	}

}

.icon {
	display: none;
}

.navbar-toggler {
	background: transparent;
	font-size: 24px;
	color: var(--color-white);
	padding: 0;
	border: none;
	box-shadow: none;
	display: none;

	@media (max-width:992px) {
		display: block;
		display: inline-block;
		position: absolute;
		right: 20px;
		margin-top: 8px;

	}

	.fas {
		color: var(--color-white);
	}
}

.fav-icon {
	background: transparent;
	font-size: 24px;
	color: var(--color-white);
	padding: 0;
	border: none;
	box-shadow: none;
	display: none;

	@media (max-width:992px) {
		display: block;
		display: inline-block;
		position: absolute;
		right: 50px;
		margin-top: 8px;

	}

	.fas {
		color: var(--color-white);
	}
}


.mob-menu {
	display: none;
	position: absolute;
	background: var(--color-white);
	width: 100%;
	padding-top: 0px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--color-blue-medium);

	.navbar-nav li {
		display: block;
		float: none;

	}

	ul {
		padding: 0;
		margin: 0;
		width: 100%;

		li {
			a {
				display: block;
				color: var(--color-main-font);
				line-height: 24px;
				text-indent: 20px;
				padding-top: 10px;
				padding-bottom: 10px;
				width: 100%;
			}



			a:hover {
				color: var(--color-white);
				background-color: var(--color-blue-medium);
				text-decoration: none;
			}



		}


	}

	.dropdown-menu {
		background-color: var(--color-white);
		position: relative;
		float: none;
		box-shadow: none;
		border-radius: 0;
		border: none;

		a {
			text-indent: 40px;
		}
	}



}





.sidenav {
	position: fixed;
	top: 210px;
	right: -75px;
	z-index: 5;
	transition: ease .3s;
	-o-transition: ease .3s;

	-webkit-transition: ease .3s;

	@media (max-width:992px) {
		display: none;
	}


	&:hover {
		right: 0;
	}

	.nav-title {
		display: flex;
		color: var(--grey3);
		padding: 10px 12px;
		position: relative;
		background-color: var(--white);
		margin-bottom: 1px;
		box-shadow: 0 0 1px rgba(0, 0, 0, .5) inset;
		height: 92px;
		align-items: center;


		&:hover {
			color: var(--primary);
			text-decoration: none;
		}



		i {
			font-size: 28px;
			margin-right: 12px;
		}
	}
}